import React, { Component } from 'react';
import $ from 'jquery'
import Loader from '../components/Loader';
import LocalizedStrings from '../components/Localization';
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment-timezone'

import { getDaPrices, getImbalancePrices, getPriceIndexes, getIceIndex, getEliaSolarImbalanceCost, getEliaWindOnshoreImbalanceCost } from '../api/market-data'

import 'moment/locale/nl-be';

moment.tz.setDefault('Europe/Brussels')

let periods = {
  'tomorrow': {
    from: moment().add(1, 'days').startOf('day').toDate(),
    to: moment().add(1, 'days').endOf('day').toDate(),
    agg: 'h',
    selector: ['daMarketPrices']
  },
  'today': {
    from: moment().startOf('day').toDate(),
    to: moment().endOf('day').toDate(),
    agg: 'h',
    selector: ['daMarketPrices', 'imbalancePrices']
  },
  'yesterday': {
    from: moment().subtract(1, 'days').startOf('day').toDate(),
    to: moment().subtract(1, 'days').endOf('day').toDate(),
    agg: 'h',
    selector: ['daMarketPrices', 'imbalancePrices']
  },
  'div1': {
    divider: true,
    selector: ['daMarketPrices']
  },
  'thisWeek': {
    from: moment().startOf('week').toDate(),
    to: moment().endOf('week').toDate(),
    agg: 'h',
    selector: ['daMarketPrices', 'imbalancePrices']
  },
  'previousWeek': {
    from: moment().subtract(1, 'weeks').startOf('week').toDate(),
    to: moment().subtract(1, 'weeks').endOf('week').toDate(),
    agg: 'h',
    selector: ['daMarketPrices', 'imbalancePrices']
  },
  'thisMonth': {
    from: moment().startOf('month').toDate(),
    to: moment().endOf('month').toDate(),
    agg: 'd',
    selector: ['daMarketPrices', 'historicIndexPrices']
  },
  'previousMonth': {
    from: moment().subtract(1, 'month').startOf('month').toDate(),
    to: moment().subtract(1, 'month').endOf('month').toDate(),
    agg: 'd',
    selector: ['daMarketPrices', 'historicIndexPrices']
  },
  'thisQuarter': {
    from: moment().quarter(moment().quarter()).startOf('quarter').toDate(),
    to: moment().quarter(moment().quarter()).endOf('quarter').toDate(),
    agg: 'd',
    selector: ['daMarketPrices', 'historicIndexPrices']
  },
  'previousQuarter': {
    from: moment().quarter(moment().quarter()).subtract(1, 'quarters').startOf('quarter').toDate(),
    to: moment().quarter(moment().quarter()).subtract(1, 'quarters').endOf('quarter').toDate(),
    agg: 'd',
    selector: ['daMarketPrices', 'historicIndexPrices']
  },
  'thisYear': {
    from: moment().startOf('year').toDate(),
    to: moment().endOf('year').toDate(),
    agg: 'm',
    selector: ['daMarketPrices', 'historicIndexPrices']
  },
  'previousYear': {
    from: moment().subtract(1, 'years').startOf('year').toDate(),
    to: moment().subtract(1, 'years').endOf('year').toDate(),
    agg: 'm',
    selector: ['daMarketPrices', 'historicIndexPrices']
  },
  'div2': {
    divider: true,
    selector: ['daMarketPrices', 'historicIndexPrices', 'imbalancePrices',]
  },
  'allTime': {
    from: undefined,
    to: undefined,
    agg: 'm',
    selector: ['daMarketPrices', 'historicIndexPrices']
  },
  'custom': {
    from: undefined,
    to: undefined,
    selector: ['daMarketPrices', 'historicIndexPrices', 'imbalancePrices']
  }
}

class MarketDataDashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openTab: undefined,
      data: undefined,
      minDate: undefined,
      maxDate: undefined,
      period: 'custom',
      from: undefined,
      to: undefined,
      averages: {}
    }

    this.setOpenTab = this.setOpenTab.bind(this)
    this.renderChart = this.renderChart.bind(this)
    this.onPeriodChange = this.onPeriodChange.bind(this)
    this.updateMarketData = this.updateMarketData.bind(this)
    this.tickFormatter = this.tickFormatter.bind(this)

    this.strings = LocalizedStrings({
      en: {
        daMarketPrices: "Day-ahead Market Prices",
        imbalanceCostMargin: "Imbalance Cost Index",
        calculation: "Calculation of the",
        eliaSolarImbalanceIndex: "Belgian Solar Imbalance Cost Index",
        eliaWindOnshoreImbalanceIndex: "Belgian Wind Imbalance Cost Index",
        imbalancePrices: "Elia Imbalance Prices",
        historicalMonthlyIndex: "Historical Monthly Index",
        futurePrices: "Future Prices",
        indexPrices: "Index prices",
        daMarketPricesDescription: "These are the wholesale prices for the electricity market cleared 1 day before delivery. These prizes are published by EpexSpot and Nordpool",
        indexPricesTitle: "Index price",
        indexPricesListDescription: "Overview of the different variable indexes used in energy contracts.",
        indexPricesListDescription2: "Average of the daily",
        indexPricesListDescription3: "Endex price",
        monthlyEndexTitle: "Monthly Endex:",
        monthlyEndexDesc: "for month M quoted during all calendar days of the previous month M-1.",
        quarterEndexTitle: "Quarter Endex:",
        quarterEndexDesc: "for quarter Q recorded during all calendar days of the previous quarter Q-1.",
        ebiqIndexTitle: "EBIQ index:",
        ebiqIndexDesc: "for Q quarter noted as from the 15th calendar day of month M-2 up to and including the 14th calendar day of the month M-1, where the month M is the first month of the relevant quarter.",
        daPricesTitle: "Day-ahead Monthly average:",
        daPricesDesc: "Monthly average of the realized prices on the day-ahead wholesale market, also known as the Belpex price. Unlike the other indices, this price is only known after the end of the month.",
        iceIndexPricesTitle: "ICE-ENDEX Belgian Baseload Future - last closing price",
        iceIndexPricesDescription: "Overview of the different future contract prices on ICE exchange",
        imbalancePricesTitle: "Elia real-time balancing prices",
        imbalancePricesDescription: "These are real-time balancing prices against which the network operator Elia settles imbalances. These prices are used by Yuso for smart battery control.",
        moreInfo: "More info",
        averagePrice: "Average price",
        avgPeakPrice: "Average peak price",
        avgOffpeakPrice: "Average offpeak price",
        maxPrice: "Max price",
        minPrice: "Min price",
        today: "Today",
        yesterday: "Yesterday",
        tomorrow: "Tomorrow",
        thisWeek: "This Week",
        thisMonth: "This Month",
        thisQuarter: "This Quarter",
        thisYear: "This Year",
        previousWeek: "Previous Week",
        previousMonth: "Previous Month",
        previousQuarter: "Previous Quarter",
        previousYear: "Previous Year",
        allTime: "All Time",
        contractStart: "Contract Start",
        custom: "Custom",
        from: "From",
        to: "To",
        apply: "Apply",
        pricesFor: "Daily prices for:",
        pricesNA: "Day-ahead prices typically become available at around 13:00",
        monthlyContracts: "Monthly contracts",
        quarterlyContracts: "Quarterly contracts",
        yearlyContracts: "Yearly contracts",
        price: "Price",
        ebiqPrice: "EBIQ Price",
        endexMPrice: "ENDEX Monthly Price",
        endexQPrice: "Endex Quarterly price",
        daPrice: "Day-ahead Monthly Average",
        imbTimePeriodInfo: "Maximum selected period is 7 days",
        source: "Source:",
        bsiThisMonth: "This month estimate",
        bsiPreviousMonth: "Previous month",
        bsiMax12Months: "Max of last 12 months",
        bsiMin12Month: "Min of last 12 months"
      },
      nl: {
        daMarketPrices: "Day-ahead Markt Prijs",
        imbalancePrices: "Elia Onbalans Prijzen",
        calculation: "Berekening van de",
        eliaSolarImbalanceIndex: "Belgian Solar Imbalance Cost Index",
        eliaWindOnshoreImbalanceCost: "Belgian Wind Imbalance Cost Index",
        imbalanceCostMargin: "Imbalance Cost Index",
        historicalMonthlyIndex: "Historische Maandelijkse Index",
        futurePrices: "Future Prijzen",
        indexPrices: "Index prijzen",
        daMarketPricesDescription: "Dit zijn de groothandelsprijzen voor de elektriciteitsmarkt die 1 dag voor levering wordt gecleared. Deze prijzen worden gepubliceerd door EpexSpot en Nordpool",
        indexPricesTitle: "Index  prijzen",
        indexPricesListDescription: "Overzicht van de verschillende variabele indexen die worden gebruikt in energiecontracten.",
        indexPricesListDescription2: "Gemiddelde van de dagelijkse",
        indexPricesListDescription3: "Endex prijs",
        monthlyEndexTitle: "Maandelijkse Endex:",
        monthlyEndexDesc: "voor maand M genoteerd tijdens alle kalenderdagen van de voorafgaande maand M-1.",
        quarterEndexTitle: "Kwartaal Endex:",
        quarterEndexDesc: "voor kwartaal Q genoteerd tijdens alle kalenderdagen van het voorafgaande kwartaal Q-1.",
        ebiqIndexTitle: "EBIQ-index:",
        ebiqIndexDesc: "voor kwartaal Q genoteerd in de periode vanaf de 15e kalenderdag van de maand M-2 tot en met de 14e kalender dag van de maand M-1, waarbij de maand M de eerste maand van het betreffende kwartaal is.",
        daPricesTitle: "Day-ahead Maandelijks gemiddelde:",
        daPricesDesc: "Maandelijks gemiddelde van de gerealiseerde prijzen op de day-ahead groothandelsmarkt ook wel bekend als Belpex prijs. In tegenstelling tot de andere indexen is deze prijs pas bekend na afloop van de maand.",
        iceIndexPricesTitle: "ICE-ENDEX Belgian Baseload Future - last closing price",
        iceIndexPricesDescription: "Overzicht van de verschillende future contract prijzen op ICE exchange",
        imbalancePricesTitle: "Elia realtime balanceringsprijzen",
        imbalancePricesDescription: "Dit zijn realtime balancering prijzen waaraan de netbeheerder Elia onbalansen afrekent. Deze prijzen worden door Yuso gebruikt voor het slim aansturen van batterijen.",
        moreInfo: "Meer info",
        averagePrice: "Gemiddelde prijs",
        avgPeakPrice: "Gemiddelde piek prijs",
        avgOffpeakPrice: "Gemiddelde daluren prijs",
        maxPrice: "Maximale prijs",
        minPrice: "Min prijs",
        today: "Vandaag",
        yesterday: "Gisteren",
        tomorrow: "Morgen",
        thisWeek: "Deze Week",
        thisMonth: "Deze Maand",
        thisQuarter: "Dit kwartaal",
        thisYear: "Dit Jaar",
        previousWeek: "Vorige Week",
        previousMonth: "Vorige Maand",
        previousQuarter: "Vorige Kwartaal",
        previousYear: "Vorig Jaar",
        allTime: "Volledig",
        contractStart: "Contract Start",
        custom: "Vrije Keuze",
        from: "Van",
        to: "Naar",
        apply: "Toepassen",
        pricesFor: "Dagprijzen voor:",
        pricesNA: "Day Ahead prijzen zijn doorgaans vanaf 13:00 beschikbaar",
        monthlyContracts: "Maandelijks contracten",
        quarterlyContracts: "Kwartaal contracten",
        yearlyContracts: "Jaar contracten",
        price: "Prijs",
        ebiqPrice: "EBIQ Prijs",
        endexMPrice: "ENDEX Maandelijks Prijs",
        endexQPrice: "Endex Kwartaal Prijs",
        daPrice: "Day-ahead Maandelijks Gemiddelde",
        imbTimePeriodInfo: "Maximaal geselecteerde periode is 7 dagen",
        source: "Bron:",
        bsiThisMonth: "This month",
        bsiPreviousMonth: "Previous month",
        bsiMax12Months: "Max of last 12 months",
        bsiMin12Month: "Min of last 12 months"
      },
      fr: {
        daMarketPrices: "Prix du marché",
        imbalancePrices: "Elia prix de déséquilibre",
        historicalMonthlyIndex: "Indice mensuel historique",
        futurePrices: "Prix futurs",
        indexPrices: "Prix indexés",
        daMarketPricesDescription: "Il s'agit des prix de gros du marché de l'électricité qui sont fixés un jour avant la livraison. Ces prix sont publiés par EpexSpot et Nordpool.",
        indexPricesTitle: "Prix d'indice",
        indexPricesListDescription: "Aperçu des différents indices variables utilisés dans les contrats d'énergie.",
        indexPricesListDescription2: "Moyenne par jour",
        indexPricesListDescription3: "Prix Endex",
        monthlyEndexTitle: "Mensuel Endex:",
        monthlyEndexDesc: "pour le mois M cité pendant tous les jours calendaires du mois précédent M-1.",
        quarterEndexTitle: "Quarter Endex:",
        quarterEndexDesc: "pour le trimestre Q enregistré pendant tous les jours calendaires du trimestre précédent Q-1.",
        ebiqIndexTitle: "EBIQ index:",
        ebiqIndexDesc: "pour le trimestre Q noté à partir du 15e jour calendaire du mois M-2 jusqu'au 14e jour calendaire inclus du mois M-1, où le mois M est le premier mois du trimestre concerné",
        daPricesTitle: "Moyenne mensuelle au jour le jour :",
        daPricesDesc: "Moyenne mensuelle des prix réalisés sur le marché de gros au jour le jour, également connue sous le nom de prix Belpex. Contrairement aux autres indices, ce prix n'est connu qu'après la fin du mois",
        iceIndexPricesTitle: "ICE-ENDEX Belgian Baseload Future - dernier prix de clôture",
        iceIndexPricesDescription: "Aperçu des différents prix des contrats futurs sur la bourse ICE",
        imbalancePricesTitle: "Prix d'équilibrage en temps réel d'Elia",
        imbalancePricesDescription: "Il s'agit de prix d'équilibrage en temps réel par rapport auxquels le gestionnaire de réseau Elia règle les déséquilibres. Ces prix sont utilisés par Yuso pour le contrôle intelligent des batteries.",
        moreInfo: "Plus d'infos",
        averagePrice: "Prix moyen",
        avgPeakPrice: "Prix de pointe moyen",
        avgOffpeakPrice: "Prix moyen hors-pointe",
        maxPrice: "Prix maximum",
        minPrice: "Prix minimum",
        today: "Aujourd'hui",
        yesterday: "Hier",
        tomorrow: "Demain",
        thisWeek: "Cette semaine",
        thisMonth: "Ce mois-ci",
        thisQuarter: "Ce trimestre",
        thisYear: "Cette année",
        previousWeek: "Semaine précédente",
        previousMonth: "Mois précédent",
        previousQuarter: "Trimestre précédent",
        previousYear: "Année précédente",
        allTime: "Tout le temps",
        contractStart: "Début du contrat",
        custom: "Personnalisé",
        from: "De",
        to: "à",
        apply: "Postulez",
        pricesFor: "Prix quotidiens pour :",
        pricesNA: "Les prix du jour deviennent généralement disponibles vers 13h00",
        monthlyContracts: "Contrats mensuels",
        quarterlyContracts: "Contrats trimestriels",
        yearlyContracts: "Contrats annuels",
        price: "Prix",
        ebiqPrice: "EBIQ Prix ",
        endexMPrice: "Prix mensuel ENDEX",
        endexQPrice: "Endex Prix trimestriel",
        daPrice: "Moyenne mensuelle au jour le jour",
        imbTimePeriodInfo: "La période maximale sélectionnée est de 7 jours",
        source: "Source:",
        bsiThisMonth: "This month",
        bsiPreviousMonth: "Previous month",
        bsiMax12Months: "Max of last 12 months",
        bsiMin12Month: "Min of last 12 months"
      }
    }, this.props.lang);
  }

  componentDidMount() {
    this.setState({ selectorSize: window.matchMedia('(max-width: 480px)').matches ? '211px' : window.matchMedia('(max-width: 650px)').matches ? '422px' : '' })
    this.setOpenTab(window.location.hash.substring(1) || 'daMarketPrices')
    $("[data-toggle='tooltip']").tooltip()
  }

  setOpenTab(id) {
    if (this.state.openTab !== id && id !== 'futureIndexPrices') {
      let minDate = undefined, maxDate = undefined, from = undefined, to = undefined

      if (id === 'daMarketPrices') {
        minDate = moment('2015-01-01', 'YYYY-MM-DD').startOf('day').toDate()
        maxDate = moment().add(1, 'days').endOf('day').toDate()

        from = moment().subtract(6, 'days').startOf('day').toDate()
        to = maxDate
      }

      else if (id === 'imbalancePrices') {
        minDate = moment('2019-01-01', 'YYYY-MM-DD').startOf('day').toDate()
        maxDate = moment().endOf('day').toDate()

        from = moment().subtract(6, 'days').startOf('day').toDate()
        to = maxDate
      }
      else if (id === 'historicIndexPrices') {
        minDate = moment('2019-10-01', 'YYYY-MM-DD').startOf('month').toDate()
        maxDate = moment().endOf('month').toDate()

        from = moment().subtract(1, 'year').startOf('month').isAfter(moment('2019-10-01', 'YYYY-MM-DD').startOf('month')) ? moment().subtract(1, 'year').startOf('month').toDate() : moment('2019-10-01', 'YYYY-MM-DD').startOf('month').toDate()
        to = maxDate
      }

      periods.allTime.from = minDate
      periods.allTime.to = maxDate

      periods.custom.from = from
      periods.custom.to = to

      this.setState({
        openTab: id,
        minDate: minDate,
        maxDate: maxDate,
        period: 'custom',
        from: from,
        to: to
      },
        () => this.updateMarketData())
    }
    else if (id === 'futureIndexPrices') {
      this.setState({ tableWidth: this.props.internal === true ? 'w-100' : window.matchMedia('(max-width: 1100px)').matches ? 'w-100' : 'w-50' })

      this.setState({
        openTab: id,
        minDate: undefined,
        maxDate: undefined,
        period: undefined,
        from: undefined,
        to: undefined
      },
        () => this.updateMarketData())
    }
  }

  onPeriodChange(event) {

    if (event.target !== undefined && event.target.id === 'periodSelector')
      this.setState({
        period: event.target.value,
        from: moment(periods[`${event.target.value}`].from).isSameOrAfter(moment(this.state.minDate)) ? periods[`${event.target.value}`].from : this.state.minDate,
        to: moment(periods[`${event.target.value}`].to).isSameOrBefore(moment(this.state.maxDate)) ? periods[`${event.target.value}`].to : this.state.maxDate
      })
    else if (event.id === 'from') {

      if (this.state.openTab === 'imbalancePrices')
        this.setState({
          period: 'custom',
          from: moment(event.value).isSameOrAfter(moment(this.state.minDate)) ? moment(event.value).toDate() : this.state.minDate,
          to: moment(this.state.to).diff(moment(event.value), 'days') <= 7 ? this.state.to : moment(event.value).add(6, 'days').endOf('day').toDate()
        })
      else
        this.setState({
          period: 'custom',
          from: moment(event.value).isSameOrAfter(moment(this.state.minDate)) ? moment(event.value).toDate() : this.state.minDate
        })
    }
    else if (event.id === 'to') {

      if (this.state.openTab === 'imbalancePrices')
        this.setState({
          period: 'custom',
          to: moment(event.value).isSameOrBefore(moment(this.state.maxDate)) ? moment(event.value).toDate() : this.state.maxDate,
          from: moment(event.value).diff(moment(this.state.from), 'days') <= 7 ? this.state.from : moment(event.value).subtract(6, 'days').startOf('day').toDate()
        })
      else
        this.setState({
          period: 'custom',
          to: moment(event.value).isSameOrBefore(moment(this.state.maxDate)) ? moment(event.value).toDate() : this.state.maxDate
        })
    }

  }

  updateMarketData() {

    this.setState({
      data: undefined,
      aggregation: undefined,
      averages: {}
    })

    if (this.state.openTab === 'futureIndexPrices')
      getIceIndex((err, res) => {
        if (err)
          return window.reportError.send(err);

        this.setState({
          data: res
        })
      })
    else {
      let agg = undefined

      if (this.state.period === 'custom') {
        const daysDiff = moment(this.state.to).diff(this.state.from, 'days')

        switch (true) {
          case (daysDiff <= 7):
            agg = 'h'
            break;

          case (daysDiff <= 100):
            agg = 'd'
            break;

          case (daysDiff > 100):
            agg = 'm'
            break;

          default:
            break;
        }
      }
      else agg = periods[`${this.state.period}`].agg

      if (this.state.openTab === 'daMarketPrices')
        getDaPrices(agg, moment(this.state.from).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.to).format('YYYY-MM-DD HH:mm:ss'), (err, res) => {
          if (err)
            return window.reportError.send(err);

          let data = res, avgPrice = 'N/A', maxPrice = 'N/A', minPrice = 'N/A', avgPeakPrice = 0, avgOffpeakPrice = 0, offpeakCounter = 0, peakCounter = 0

          if (data.length > 0) {
            avgPrice = data.reduce((acc, curr) => (acc.price || acc) + curr.price)
            avgPrice = (avgPrice / data.length).toFixed(2)
            maxPrice = data.reduce((prev, curr) => Math.max((prev.price || prev), curr.price)).toFixed(2)
            minPrice = data.reduce((prev, curr) => Math.min((prev.price || prev), curr.price)).toFixed(2)

            if (agg === 'h')
              data.forEach(element => {
                if (element.htype === 'SU') {
                  avgOffpeakPrice += element.price
                  offpeakCounter++
                } else if (element.htype === 'NU') {
                  avgPeakPrice += element.price
                  peakCounter++
                }
              })
            else if (agg === 'd' || agg === 'm')
              data.forEach(element => {
                if (element.offpeak_price !== null) {
                  avgOffpeakPrice += element.offpeak_price
                  offpeakCounter++
                }

                if (element.peak_price !== null) {
                  avgPeakPrice += element.peak_price
                  peakCounter++
                }
              })

            avgOffpeakPrice = (avgOffpeakPrice / offpeakCounter).toFixed(2)
            avgPeakPrice = (avgPeakPrice / peakCounter).toFixed(2)

          }

          this.setState({
            data: res,
            aggregation: agg,
            averages: {
              avgPrice: avgPrice,
              avgPeakPrice: avgPeakPrice,
              avgOffpeakPrice: avgOffpeakPrice,
              maxPrice: maxPrice,
              minPrice: minPrice
            }
          })
        })
      else if (this.state.openTab === 'imbalancePrices')
        getImbalancePrices(moment(this.state.from).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.to).format('YYYY-MM-DD HH:mm:ss'), (err, res) => {
          if (err)
            return window.reportError.send(err);

          this.setState({
            data: res,
          })
        })
      else if (this.state.openTab === 'historicIndexPrices')
        getPriceIndexes(moment(this.state.from).format('YYYY-MM-DD HH:mm:ss'), moment(this.state.to).format('YYYY-MM-DD HH:mm:ss'), (err, res) => {
          if (err)
            return window.reportError.send(err);

          let mEndexAvg = (res.reduce(((prev, curr) => (prev.endex_monthly_price || prev) + curr.endex_monthly_price)) / res.length).toFixed(2),
            qEndexAvg = (res.reduce(((prev, curr) => (prev.endex_quarterly_price || prev) + curr.endex_quarterly_price)) / res.length).toFixed(2),
            ebiqIndexAvg = (res.reduce(((prev, curr) => (prev.ebiq_price || prev) + curr.ebiq_price)) / res.length).toFixed(2),
            daAvg = (res.reduce(((prev, curr) => (prev.da_monthly_price || prev) + curr.da_monthly_price)) / res.length).toFixed(2)

          this.setState({
            data: res,
            averages: {
              mEndexAvg: mEndexAvg,
              qEndexAvg: qEndexAvg,
              ebiqIndexAvg: ebiqIndexAvg,
              daAvg: daAvg
            }
          })
        })
      else if (this.state.openTab === 'eliaSolarImbalanceIndex')
        getEliaSolarImbalanceCost('202101', (err, res) => {

          if (err) {
            return window.reportError.send(err)
          }

          this.setState({
            data: res,
            averages: {
              lastMonth: (res.find(x => x.monthid === moment().subtract(1, 'month').format('YYYYMM')) || {}).be_solar_imbalance_cost_index || 'N/A',
              currentMonth: (res.find(x => x.monthid === moment().format('YYYYMM')) || {}).be_solar_imbalance_cost_index || 'N/A',
              maxValue: (res.filter(x => x.monthid > moment().subtract(12, 'month').format('YYYYMM')) || []).map(x => x.be_solar_imbalance_cost_index).reduce((a, b) => b > a ? b : a),
              minValue: (res.filter(x => x.monthid > moment().subtract(12, 'month').format('YYYYMM')) || []).map(x => x.be_solar_imbalance_cost_index).reduce((a, b) => b < a ? b : a)
            }
          })
        })
      else if (this.state.openTab === 'eliaWindOnshoreImbalanceIndex')
        getEliaWindOnshoreImbalanceCost('202101', (err, res) => {

          if (err) {
            return window.reportError.send(err)
          }

          this.setState({
            data: res,
            averages: {
              lastMonth: (res.find(x => x.monthid === moment().subtract(1, 'month').format('YYYYMM')) || {}).be_wind_onshore_imbalance_cost_index || 'N/A',
              currentMonth: (res.find(x => x.monthid === moment().format('YYYYMM')) || {}).be_wind_onshore_imbalance_cost_index || 'N/A',
              maxValue: (res.filter(x => x.monthid > moment().subtract(12, 'month').format('YYYYMM')) || []).map(x => x.be_wind_onshore_imbalance_cost_index).reduce((a, b) => b > a ? b : a),
              minValue: (res.filter(x => x.monthid > moment().subtract(12, 'month').format('YYYYMM')) || []).map(x => x.be_wind_onshore_imbalance_cost_index).reduce((a, b) => b < a ? b : a)
            }
          })
        })
    }
  }

  tickFormatter(value) {

    let format = 'YYYY-MM-DD HH:mm'

    if (this.state.openTab === 'historicIndexPrices')
      format = 'YYYY-MM'
    else
      switch (this.state.aggregation) {
        case 'h':
          format = 'YYY-MM-DD HH:mm'
          break;

        case 'd':
          format = 'YYYY-MM-DD'
          break;

        case 'm':
          format = 'YYYY-MM'
          break;

        default:
          break;
      }

    return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format)
  }

  renderChart() {

    const { from, to, minDate, maxDate } = this.state
    return (
      <div>
        <div className='form-inline justify-content-center'>
          {
            (this.state.openTab === 'eliaSolarImbalanceIndex' || this.state.openTab === 'eliaWindOnshoreImbalanceIndex') ? null : <select className='form-control mb-2' id='periodSelector' onChange={this.onPeriodChange} value={this.state.period} style={{ width: this.state.selectorSize }}>
              {
                Object.keys(periods).map(period => {
                  if (periods[period].divider && periods[period].selector.indexOf(this.state.openTab) > -1)
                    return <option key={period} disabled value=''>────────────</option>

                  if (periods[period].selector.indexOf(this.state.openTab) > -1)
                    return <option key={period} value={period} onClick={this.onPeriodChange}>{this.strings[period]}</option>
                  else return null
                })
              }
            </select>
          }
          {
            (this.state.openTab === 'eliaSolarImbalanceIndex' || this.state.openTab === 'eliaWindOnshoreImbalanceIndex') ? null : <div className='row justify-content-center mr-2 ml-2'>
              <DatePicker
                selected={from}
                dateFormat={this.state.openTab !== 'historicIndexPrices' ? 'dd-MM-yyyy' : 'MM-yyyy'}
                minDate={minDate}
                maxDate={to}
                className={`form-control mb-2 ${this.state.selectorSize === '' ? 'mr-2' : ''}`}
                onChange={date => this.onPeriodChange({ id: 'from', value: date })}
                selectsStart
                startDate={from}
                endDate={to}
                locale={this.props.lang}
                showMonthYearPicker={this.state.openTab !== 'historicIndexPrices' ? false : true}
              />
              <DatePicker
                selected={to}
                dateFormat={this.state.openTab !== 'historicIndexPrices' ? 'dd-MM-yyyy' : 'MM-yyyy'}
                minDate={from}
                maxDate={maxDate}
                className={`form-control mb-2`}
                onChange={date => this.onPeriodChange({ id: 'to', value: date })}
                selectsEnd
                startDate={from}
                endDate={to}
                locale={this.props.lang}
                showMonthYearPicker={this.state.openTab !== 'historicIndexPrices' ? false : true}
              />
            </div>
          }
          {
            this.state.openTab === 'imbalancePrices' && this.state.period === 'custom'
              ? <h4><i className='fa fa-info-circle ml-1 mr-3 text-info' data-toggle='tooltip' title={this.strings.imbTimePeriodInfo}></i></h4>
              : null
          }
          {
            (this.state.openTab === 'eliaSolarImbalanceIndex' || this.state.openTab === 'eliaWindOnshoreImbalanceIndex') ? null : <div className='row justify-content-center mr-2 ml-2'>
            </div>
          }

          {
            (this.state.openTab === 'eliaSolarImbalanceIndex' || this.state.openTab === 'eliaWindOnshoreImbalanceIndex') ? null : <button type='button' className='btn btn-primary btn-picker mb-2' onClick={() => this.updateMarketData()} style={{ width: this.state.selectorSize }}>
              {this.strings.apply} <i className='fas fa-chevron-right ml-1'></i>
            </button>
          }

        </div>
        {
          this.state.data === undefined
            ? <Loader />
            : this.state.data.length > 0
              ? <div>
                {
                  this.state.openTab === 'daMarketPrices' && this.state.averages !== undefined
                    ? <div className='row mt-5 mb-3'>
                      <div className='col-sm d-flex justify-content-around flex-column flex-md-row'>
                        <div className='mb-2 text-center'>
                          <h5 className='text-primary font-weight-light mb-2'>{this.strings.averagePrice}</h5>
                          <h4 className='text-primary font-weight-light'>{this.state.averages.avgPrice} €/MWh</h4>
                        </div>

                        <div className='border-right border-warning w-0 mb-2'></div>

                        <div className='mb-2 text-center'>
                          <h5 className='text-primary font-weight-light mb-2'>{this.strings.avgPeakPrice}</h5>
                          <h4 className='text-primary font-weight-light'>{this.state.averages.avgPeakPrice} €/MWh</h4>
                        </div>

                        <div className='border-right border-warning w-0 mb-2'></div>

                        <div className='mb-2 text-center'>
                          <h5 className='text-primary font-weight-light mb-2'>{this.strings.avgOffpeakPrice}</h5>
                          <h4 className='text-primary font-weight-light'>{this.state.averages.avgOffpeakPrice} €/MWh</h4>
                        </div>

                        <div className='border-right border-warning w-0 mb-2'></div>

                        <div className='mb-2 text-center'>
                          <h5 className='text-primary font-weight-light mb-2'>{this.strings.maxPrice}</h5>
                          <h4 className='text-primary font-weight-light'>{this.state.averages.maxPrice} €/MWh</h4>
                        </div>

                        <div className='border-right border-warning w-0 mb-2'></div>

                        <div className='mb-2 text-center'>
                          <h5 className='text-primary font-weight-light mb-2'>{this.strings.minPrice}</h5>
                          <h4 className='text-primary font-weight-light'>{this.state.averages.minPrice} €/MWh</h4>
                        </div>
                      </div>
                    </div>
                    : this.state.openTab === 'historicIndexPrices' && this.state.averages !== undefined
                      ?
                      <div className='row mt-5 mb-3'>
                        <div className='col-sm d-flex justify-content-around flex-column flex-md-row'>
                          <div className='mb-2 text-center'>
                            <h5 className='text-primary font-weight-light mb-2'>Monthly Endex Average</h5>
                            <h4 className='text-primary font-weight-light'>{this.state.averages.mEndexAvg} €/MWh</h4>
                          </div>

                          <div className='border-right border-warning w-0 mb-2'></div>

                          <div className='mb-2 text-center'>
                            <h5 className='text-primary font-weight-light mb-2'>Quarterly Endex Average</h5>
                            <h4 className='text-primary font-weight-light'>{this.state.averages.qEndexAvg} €/MWh</h4>
                          </div>

                          <div className='border-right border-warning w-0 mb-2'></div>

                          <div className='mb-2 text-center'>
                            <h5 className='text-primary font-weight-light mb-2'>EBIQ Index Average</h5>
                            <h4 className='text-primary font-weight-light'>{this.state.averages.ebiqIndexAvg} €/MWh</h4>
                          </div>

                          <div className='border-right border-warning w-0 mb-2'></div>

                          <div className='mb-2 text-center'>
                            <h5 className='text-primary font-weight-light mb-2'>Day-ahead Average </h5>
                            <h4 className='text-primary font-weight-light'>{this.state.averages.daAvg} €/MWh</h4>
                          </div>
                        </div>
                      </div>
                      : this.state.openTab === 'eliaSolarImbalanceIndex' && this.state.averages !== undefined
                        ?
                        <div className='row mt-5 mb-3'>
                          <div className='col-sm d-flex justify-content-around flex-column flex-md-row'>
                            <div className='mb-2 text-center'>
                              <h5 className='text-primary font-weight-light mb-2'>{this.strings.bsiThisMonth}</h5>
                              <h4 className='text-primary font-weight-light'>{this.state.averages.currentMonth} {this.state.averages.currentMonth !== 'N/A' ? '€/MWh' : null}</h4>
                            </div>

                            <div className='border-right border-warning w-0 mb-2'></div>

                            <div className='mb-2 text-center'>
                              <h5 className='text-primary font-weight-light mb-2'>{this.strings.bsiPreviousMonth}</h5>
                              <h4 className='text-primary font-weight-light'>{this.state.averages.lastMonth} {this.state.averages.lastMonth !== 'N/A' ? '€/MWh' : null}</h4>
                            </div>
                            <div className='border-right border-warning w-0 mb-2'></div>

                            <div className='mb-2 text-center'>
                              <h5 className='text-primary font-weight-light mb-2'>{this.strings.bsiMax12Months}</h5>
                              <h4 className='text-primary font-weight-light'>{this.state.averages.maxValue} €/MWh</h4>
                            </div>
                            <div className='border-right border-warning w-0 mb-2'></div>

                            <div className='mb-2 text-center'>
                              <h5 className='text-primary font-weight-light mb-2'>{this.strings.bsiMin12Month}</h5>
                              <h4 className='text-primary font-weight-light'>{this.state.averages.minValue} €/MWh</h4>
                            </div>

                          </div>
                        </div>

                        : this.state.openTab === 'eliaWindOnshoreImbalanceIndex' && this.state.averages !== undefined
                          ?
                          <div className='row mt-5 mb-3'>
                            <div className='col-sm d-flex justify-content-around flex-column flex-md-row'>
                              <div className='mb-2 text-center'>
                                <h5 className='text-primary font-weight-light mb-2'>{this.strings.bsiThisMonth}</h5>
                                <h4 className='text-primary font-weight-light'>{this.state.averages.currentMonth} {this.state.averages.currentMonth !== 'N/A' ? '€/MWh' : null}</h4>
                              </div>

                              <div className='border-right border-warning w-0 mb-2'></div>

                              <div className='mb-2 text-center'>
                                <h5 className='text-primary font-weight-light mb-2'>{this.strings.bsiPreviousMonth}</h5>
                                <h4 className='text-primary font-weight-light'>{this.state.averages.lastMonth} {this.state.averages.lastMonth !== 'N/A' ? '€/MWh' : null}</h4>
                              </div>
                              <div className='border-right border-warning w-0 mb-2'></div>

                              <div className='mb-2 text-center'>
                                <h5 className='text-primary font-weight-light mb-2'>{this.strings.bsiMax12Months}</h5>
                                <h4 className='text-primary font-weight-light'>{this.state.averages.maxValue} €/MWh</h4>
                              </div>
                              <div className='border-right border-warning w-0 mb-2'></div>

                              <div className='mb-2 text-center'>
                                <h5 className='text-primary font-weight-light mb-2'>{this.strings.bsiMin12Month}</h5>
                                <h4 className='text-primary font-weight-light'>{this.state.averages.minValue} €/MWh</h4>
                              </div>

                            </div>
                          </div>
                          : null
                }
                <div className="row mt-4 mb-4" style={{ height: '400px' }}>
                  {
                    !(this.state.openTab === 'eliaSolarImbalanceIndex' || this.state.openTab === 'eliaWindOnshoreImbalanceIndex') ? <ResponsiveContainer>

                      <LineChart data={this.state.data} width={'100%'}>
                        <XAxis
                          dataKey={this.state.openTab !== 'historicIndexPrices' ? 'dtlt' : 'delivery_period'}
                          type='category'
                          minTickGap={this.state.openTab === 'imbalancePrices' ? 48 : this.state.openTab === 'historicIndexPrices' ? 25 : 12}
                          tickFormatter={this.tickFormatter} />
                        <YAxis />
                        <CartesianGrid strokeDasharray='3 3' />
                        <Tooltip labelFormatter={this.tickFormatter} formatter={value => parseFloat(value).toFixed(2)} />
                        {
                          this.state.openTab !== 'historicIndexPrices'
                            ? <Line dataKey='price' name={this.strings.price} unit=' €/MWh' stroke='#006087' type='stepAfter' dot={false} />
                            :
                            [
                              <Line dataKey='ebiq_price' id='price' name={this.strings.ebiqPrice} unit=' €/MWh' stroke='#006087' type='stepAfter' dot={false} key='ebiqPrice' />,
                              <Line dataKey='endex_monthly_price' id='price' name={this.strings.endexMPrice} unit=' €/MWh' stroke='#93b0c5' type='stepAfter' dot={false} key='endexMPrice' />,
                              <Line dataKey='endex_quarterly_price' id='price' name={this.strings.endexQPrice} unit=' €/MWh' stroke='#dddc01' type='stepAfter' dot={false} key='endexQPrice' />,
                              <Line dataKey='da_monthly_price' id='price' name={this.strings.daPrice} unit=' €/MWh' stroke='#4BBB1A' type='stepAfter' dot={false} key='daPrice' />,
                              <Legend iconType='line' key='legend' />
                            ]
                        }
                      </LineChart>
                    </ResponsiveContainer>
                      : this.state.openTab === 'eliaSolarImbalanceIndex' ?
                        <div style={{ width: '100%', height: '375px' }}>
                          <ResponsiveContainer>
                            <LineChart data={this.state.data} margin={{ top: 20, right: 20, bottom: 40, left: 20, }} width={'100%'}>
                              <XAxis dataKey={'dateId'} angle={-45} textAnchor="end" interval={0} />
                              <YAxis domain={[0, Math.floor(this.state.averages.maxValue / 5) * 5 + 10]} tickCount={Math.floor(this.state.averages.maxValue / 5) + 3} label={{ value: '€ / MWh', angle: -90, position: 'insideLeft' }} />
                              <CartesianGrid strokeDasharray='3 3' />
                              <Tooltip />
                              <Line dataKey='be_solar_imbalance_cost_index' name={this.strings.imbalanceCostMargin} unit=' €/MWh' stroke='#006087' type='line' dot={true} key='be_solar_imbalance_cost_indexPrice' />
                            </LineChart>
                          </ResponsiveContainer>
                          <div className='p-4 text-muted'>
                            {this.strings.source} YUSO calculation based on <a href="https://opendata.elia.be/" target="_blank" rel="noreferrer">Elia Open Data</a> platform. For detailed calculation method, see <a href="https://dev.my.yuso.io/bsi" target="_blank" rel="noreferrer">{this.strings.calculation} Belgian Solar Imbalance Cost Index</a>.
                          </div>
                        </div>
                        :
                        this.state.openTab === 'eliaWindOnshoreImbalanceIndex' ?
                          <div style={{ width: '100%', height: '375px' }}>
                            <ResponsiveContainer>
                              <LineChart data={this.state.data} margin={{ top: 20, right: 20, bottom: 40, left: 20, }} width={'100%'}>
                                <XAxis dataKey={'dateId'} angle={-45} textAnchor="end" interval={0} />
                                <YAxis domain={[0, Math.floor(this.state.averages.maxValue / 5) * 5 + 20]} tickCount={Math.floor(this.state.averages.maxValue / 5) + 5} label={{ value: '€ / MWh', angle: -90, position: 'insideLeft' }} />
                                <CartesianGrid strokeDasharray='3 3' />
                                <Tooltip />
                                <Line dataKey='be_wind_onshore_imbalance_cost_index' name={this.strings.imbalanceCostMargin} unit=' €/MWh' stroke='#006087' type='line' dot={true} key='be_wind_onshore_imbalance_cost_index' />
                              </LineChart>
                            </ResponsiveContainer>
                            <div className='p-4 text-muted'>
                              {this.strings.source} YUSO calculation based on <a href="https://opendata.elia.be/" target="_blank" rel="noreferrer">Elia Open Data</a> platform. For detailed calculation method, see <a href="https://dev.my.yuso.io/bonwi" target="_blank" rel="noreferrer">{this.strings.calculation} Belgian Onshore Wind Imbalance Index. </a>.

                            </div>
                          </div>
                          : null
                  }

                </div>
              </div>
              : this.state.openTab === 'daMarketPrices'
                ? <div class='height-400 d-flex justify-content-center align-items-center mt-5 mb-3'>
                  <h7 className='alert alert-info'>
                    <i className='fas fa-info-circle mr-2'></i>{this.strings.pricesNA}
                  </h7>
                </div>
                : <div class='height-400 d-flex justify-content-center align-items-center mt-5 mb-3'>
                  <h7 className='alert alert-info'><i className='fas fa-info-circle mr-2'></i> N/A </h7>
                </div>
        }


      </div>
    )

  }

  render() {
    return (
      <div className='row bg-white'>
        <div className='col-sm px-2 px-xl-5 py-3'>

          <ul className='nav nav-tabs'>
            <li className='nav-item' key={'daMarketPrices'}>
              <a href={'#daMarketPrices'}
                className={`nav-link cursor-pointer ${this.state.openTab === 'daMarketPrices' ? 'active' : ''}`}
                onClick={(e) => { this.setOpenTab('daMarketPrices'); }}
              >
                {this.strings.daMarketPrices}
              </a>
            </li>
            <li className='nav-item' key={'imbalancePrices'}>
              <a href={'#imbalancePrices'}
                className={`nav-link cursor-pointer ${this.state.openTab === 'imbalancePrices' ? 'active' : ''}`}
                onClick={(e) => { this.setOpenTab('imbalancePrices'); }}
              >
                {this.strings.imbalancePrices}
              </a>
            </li>
            <li className='nav-item' key={'eliaSolarImbalanceIndex'}>
              <a href={'#eliaSolarImbalanceIndex'}
                className={`nav-link cursor-pointer ${this.state.openTab === 'eliaSolarImbalanceIndex' ? 'active' : ''}`}
                onClick={(e) => { this.setOpenTab('eliaSolarImbalanceIndex'); }}
              >
                {this.strings.eliaSolarImbalanceIndex}
              </a>
            </li>
            <li className='nav-item' key={'eliaWindOnshoreImbalanceIndex'}>
              <a href={'#eliaWindOnshoreImbalanceIndex'}
                className={`nav-link cursor-pointer ${this.state.openTab === 'eliaWindOnshoreImbalanceIndex' ? 'active' : ''}`}
                onClick={(e) => { this.setOpenTab('eliaWindOnshoreImbalanceIndex'); }}
              >
                {this.strings.eliaWindOnshoreImbalanceIndex}
              </a>
            </li>
            {
              this.props.internal !== undefined && this.props.internal === true && this.props.selectedCompany.role === 'admin' ?
                [
                  <li className='nav-item' key={'historicIndexPrices'}>
                    <a href={'#historicIndexPrices'}
                      className={`nav-link cursor-pointer ${this.state.openTab === 'historicIndexPrices' ? 'active' : ''}`}
                      onClick={(e) => { this.setOpenTab('historicIndexPrices'); }}
                    >
                      {this.strings.historicalMonthlyIndex}
                    </a>
                  </li>,
                  <li className='nav-item' key={'futureIndexPrices'}>
                    <a href={'#futureIndexPrices'}
                      className={`nav-link cursor-pointer ${this.state.openTab === 'futureIndexPrices' ? 'active' : ''}`}
                      onClick={(e) => { this.setOpenTab('futureIndexPrices'); }}
                    >
                      {this.strings.futurePrices}
                    </a>
                  </li>
                ] : null
            }
          </ul>

          <div className={'tab-content border' + (this.props.internal === undefined || this.props.internal === false ? '-top ' : ' ') + 'border-warning p-4'}>

            <div className={'tab-pane active ' + (this.state.openTab === 'daMarketPrices' ? 'd - block' : 'd-none')}
              aria-expanded={(this.state.openTab === 'daMarketPrices')}
              key={'daMarketPrices'}
            >
              <h5>{this.strings.daMarketPrices}</h5>
              <p>{this.strings.daMarketPricesDescription}
                <span className='text-primary text-underline cursor-pointer d-inline-block ml-2'>
                  <i className='fas fa-info-circle mr-2'></i><a href='https://yuso.be/blog/injectie/wat-zijn-belpex-prijzen/' target='_blank' rel='noopener noreferrer'>{this.strings.moreInfo}</a>
                </span>
              </p>
              {this.renderChart()}
              {
                this.props.internal === undefined || this.props.internal === false ?
                  [
                    <br />,
                    <p className='text-muted'>{this.strings.source} Entsoe</p>
                  ] : null
              }
            </div>

            <div className={'tab-pane active ' + (this.state.openTab === 'imbalancePrices' ? 'd - block' : 'd-none')}
              aria-expanded={(this.state.openTab === 'imbalancePrices')}
              key={'imbalancePrices'}
            >
              <h5>{this.strings.imbalancePricesTitle}</h5>
              <p>{this.strings.imbalancePricesDescription}</p>
              {this.renderChart('imbalancePrices')}
              {
                this.props.internal === undefined || this.props.internal === false ?
                  [
                    <br />,
                    <p className='text-muted'>{this.strings.source} Elia</p>
                  ] : null
              }
            </div>
            <div className={'tab-pane active ' + (this.state.openTab === 'eliaSolarImbalanceIndex' ? 'd - block' : 'd-none')}
              aria-expanded={(this.state.openTab === 'eliaSolarImbalanceIndex')}
              key={'eliaSolarImbalanceIndex'}
            >
              {this.renderChart('eliaSolarImbalanceIndex')}
              {/* {
                this.props.internal === undefined || this.props.internal === false  ?
                  [
                    <br />,
                    <p className='text-muted'>{this.strings.source} Elia</p>
                  ] : null
              } */}
            </div>
            <div className={'tab-pane active ' + (this.state.openTab === 'eliaWindOnshoreImbalanceIndex' ? 'd - block' : 'd-none')}
              aria-expanded={(this.state.openTab === 'eliaWindOnshoreImbalanceIndex')}
              key={'eliaWindOnshoreImbalanceIndex'}
            >
              {this.renderChart('eliaWindOnshoreImbalanceIndex')}
            </div>

            {
              this.props.internal !== undefined && this.props.internal === true ?
                <div>

                  <div className={'tab-pane active ' + (this.state.openTab === 'historicIndexPrices' ? 'd - block' : 'd-none')}
                    aria-expanded={(this.state.openTab === 'historicIndexPrices')}
                    key={'historicIndexPrices'}
                  >
                    <h5>{this.strings.indexPricesTitle}</h5>
                    <p>{this.strings.indexPricesListDescription}</p>
                    <ul className='mb-3'>
                      <li><b>{this.strings.monthlyEndexTitle}</b> {this.strings.indexPricesListDescription2} <a href="https://www.theice.com/products/27993084/Belgian-Power-Baseload-Futures">{this.strings.indexPricesListDescription3}</a> {this.strings.monthlyEndexDesc}</li>
                      <li><b>{this.strings.quarterEndexTitle}</b> {this.strings.indexPricesListDescription2} <a href="https://www.theice.com/products/27993084/Belgian-Power-Baseload-Futures">{this.strings.indexPricesListDescription3}</a> {this.strings.quarterEndexDesc}</li>
                      <li><b>{this.strings.ebiqIndexTitle}</b> {this.strings.indexPricesListDescription2} <a href="https://www.theice.com/products/27993084/Belgian-Power-Baseload-Futures">{this.strings.indexPricesListDescription3}</a> {this.strings.ebiqIndexDesc}</li>
                      <li><b>{this.strings.daPricesTitle}</b> {this.strings.indexPricesListDescription2} <a href="https://www.theice.com/products/27993084/Belgian-Power-Baseload-Futures">{this.strings.indexPricesListDescription3}</a> {this.strings.daPricesDesc}</li>
                    </ul>
                    {this.renderChart()}
                  </div>

                  <div className={'tab-pane active ' + (this.state.openTab === 'futureIndexPrices' ? 'd - block' : 'd-none')}
                    aria-expanded={(this.state.openTab === 'futureIndexPrices')}
                    key={'futureIndexPrices'}
                  >
                    <h5>{this.strings.iceIndexPricesTitle}</h5>
                    <p>{this.strings.iceIndexPricesDescription}</p>
                    {this.state.data === undefined ? <Loader /> : this.state.data.length ?
                      <div>
                        <div className={`table-responsive ${this.state.tableWidth}`}>
                          <table className='table table-bordered'>
                            <thead>
                              <tr>
                                <th className='text-center heavy-border'>{this.strings.monthlyContracts}</th>
                                <th className='text-center heavy-border'>{this.strings.quarterlyContracts}</th>
                                <th className='text-center'>{this.strings.yearlyContracts}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className='pb-0'>
                                  <table className='table table-borderless'>
                                    <tbody>
                                      {this.state.data.filter(x => x.type === 'M').map(x => {
                                        return (
                                          <tr key={x.delivery_period}>
                                            <td className='p-2'>{x.delivery_period.replace('-', '‑')}</td>
                                            <td className='p-2'>{x.price}</td>
                                            <td className='p-2'>{x.type}</td>
                                          </tr>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </td>
                                <td className='pb-0'>
                                  <table className='table table-borderless'>
                                    <tbody>
                                      {this.state.data.filter(x => x.type === 'Q').map(x => {
                                        return (
                                          <tr key={x.delivery_period}>
                                            <td className='p-2'>{x.delivery_period.replace('-', '‑')}</td>
                                            <td className='p-2'>{x.price}</td>
                                            <td className='p-2'>{x.type}</td>
                                          </tr>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </td>
                                <td className='pb-0'>
                                  <table className='table table-borderless'>
                                    <tbody>
                                      {this.state.data.filter(x => x.type === 'Y').map(x => {
                                        return (
                                          <tr key={x.delivery_period}>
                                            <td className='p-2'>{x.delivery_period}</td>
                                            <td className='p-2'>{x.price}</td>
                                            <td className='p-2'>{x.type}</td>
                                          </tr>
                                        )
                                      })
                                      }
                                    </tbody>
                                  </table>
                                </td>
                              </tr>
                            </tbody>

                          </table>
                        </div>
                        <p>{this.strings.pricesFor} {this.state.data[0].date}</p>
                      </div> : null
                    }
                  </div>
                </div> : null
            }

          </div>
        </div>
      </div>
    );
  }
}

export default MarketDataDashboard;
