let config = {
    BASE_URL: 'http://localhost:3001', // Local Dev Server API URL
    TRACKING: {
        url: 'rocket.yuso.io',
        script: 'js/',
        siteId: '1'
    },
    YUSO_PARTNER_ID: 1,
    YUSO_PARTNER_CONTACT_ID: 2389,
    MAINTENANCE_MV: false,
    DEV: true,
    GOOGLE_OAUTH2_CLIENT_ID: '674020123718-jh44ia6kchcpqo2fg0ioptracgk1t2he.apps.googleusercontent.com'
};

// Production API URL
if (window.location.hostname === 'dev.my.yuso.io') {
    config.BASE_URL = 'https://dev.my.yuso.io'
    config.DEV = false
}

export default config
